<template>
	<div class="TelecastDetail">
		<loading-page :loading="loadingPage"></loading-page>
		<w-navTab titleText="直播详情"></w-navTab>
		<div class="container">
			<div class="head-top">
				{{detail.title}}
			</div>
			<div class="content">
				<div class="content-title">直播内容：</div>
				<div class="content-text" v-html="detail.content"></div>
			</div>
			<div class="content">
				<div class="content-title">直播时间：</div>
				<div class="content-text">
					{{detail.play_date}}
				</div>
			</div>
			<div class="content">
				<div class="content-title">出镜人员：</div>
				<div class="content-text">
					{{detail.guest}}
				</div>
			</div>
		</div>

		<div class="body-img" v-if="detail.state != 3">
			<img :src="detail.thumb" alt="">
		</div>
		<div class="body-video" v-if="detail.state == 3 && detail.playback">
			<xg-player :url="detail.playback"></xg-player>
		</div>
		<div class="footer">
			<button class="footer-botton" :data-clipboard-text="detail.link">复制抖音口令</button>
		</div>
	</div>
</template>

<script>
	import Live from '@/api/live';
	import Vant from '@/vendor/vant';
	import Clipboard from "clipboard";
	import LoadingPage from '@/components/LoadingPage';
	import XgPlayer from '@/components/xgplayer.vue';

	export default {
		name: 'TelecastDetail',
		data() {
			return {
				detail: "",
				loadingPage: false,
				videoUrl: ""
			}
		},
		mounted() {
			// 复制抖音口令
			var clipboard = new Clipboard('.footer-botton');
			clipboard.on('success', function(e) {
				console.info('Text:', e.text);
				e.clearSelection();
				Vant.Toast.success('复制成功');
			});
		},
		created() {
			Live.liveDetail({
					live_id: this.$route.params.id
				})
				.then(result => {
					this.detail = result.data
					if (this.detail.state == 3 && this.detail.playback == "") {
						Vant.Toast('暂无直播回放地址');
					}
					this.loadingPage = false;

				}, error => {
					Vant.Toast(error.msg);
				})
		},
		methods: {
			
		},
		components: {
			LoadingPage,
			XgPlayer
		}
	}
</script>

<style scoped lang="less">
	.TelecastDetail {
		padding-bottom: 70px;
		box-sizing: border-box;

		.container {
			margin: 0 8px;
			margin-top: 8px;
			padding: 16px 20px;
			box-sizing: border-box;
			background: #FFF;
			border-radius: 6px;

			.head-top {
				text-align: center;
				font-weight: bold;
				font-size: 20px;
				line-height: 29px;
				color: #44484E;
				padding-bottom: 16px;
				border-bottom: 1px solid #F5F6F8;
			}

			.content:first-child {
				margin-top: 12px;
			}

			.content {
				margin-top: 16px;

				.content-title {
					padding: 5px 10px;
					font-weight: bold;
					font-size: 17px;
					line-height: 20px;
					color: #44484E;
					background: linear-gradient(90deg, rgba(116, 111, 243, 0.14) 0%, rgba(116, 111, 243, 0) 100%);
					margin-bottom: 8px;
				}

				.content-text {
					font-size: 14px;
					line-height: 22px;
					color: #44484E;
					padding-left: 10px;
				}
			}
		}

		.body-img {
			width: 100%;
			height: 100%;
			margin-top: 8px;
			padding: 0 8px;
			box-sizing: border-box;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.body-video {
			width: 100%;
			height: 300px;
			padding: 0 8px;
			margin-top: 10px;
			box-sizing: border-box;

			video {
				width: 100%;
				height: 300px !important;
			}
		}

		.footer {
			position: fixed;
			width: 100%;
			bottom: 8px;
			left: 0;
			padding: 8px;
			box-sizing: border-box;

			button {
				border: 0;
			}

			.footer-botton {
				width: 100%;
				background: linear-gradient(92.48deg, #128AF6 0%, #3377FF 100%);
				box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
				border-radius: 100px;
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				color: #FFF;
				padding: 11px 0;
			}
		}
	}
</style>
